import React, { useState, useEffect } from 'react'
import { PlusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import "./style.css"

export const Box = ({ title, cardSelectionHandler, hasSelector, isRedCard }) => {
  const [display, setDisplay] = useState('scale(1)');
  const [backgroundColor, setBackgroundColor] = useState();

  useEffect(() => {
    if (isRedCard) {
      setBackgroundColor('#C7332D')
    } else {
      setBackgroundColor('#353F7E')
    }
  }, [isRedCard]);

  const toggleVisibility = () => {
    if (display === 'scale(1)') {
      setDisplay('scale(0)')
    }
    if (display === 'scale(0)') {
      setDisplay('scale(1)')
    }
  }

  return (
    <div style={{ transform: display, background: backgroundColor }} className={'box-container'}>
      <span>{title}</span>
      {hasSelector ?
        <PlusCircleOutlined className={'box-selector'} onClick={() => {
          cardSelectionHandler(title)
          toggleVisibility()
        }} /> : 
        <CloseCircleOutlined className={'box-selector'} onClick={() => { 
          cardSelectionHandler(title)
          toggleVisibility()
        }} />
      }
    </div>)
}
