import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from '../../utils/ItemTypes'
import { CloseCircleOutlined } from '@ant-design/icons';

import "./style.css"

const style = {
  height: '80px',
  width: '96px',
  color: 'white',
  textAlign: 'center',
  fontSize: '10px',
  lineHeight: 'normal',
  float: 'left',
  border: '3px solid black',
  borderRadius: '8px',
}

export const Doublebin = forwardRef((props, ref) => {
  const { handleCardPairDropped, handleCardPairRemoved, selectPhase, handleSelectForCompass, selectedCards } = props;
  const [droppedCard, setDroppedCard] = useState()
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.BOX,
    drop: (item, monitor) => {
      const { card } = item
      setDroppedCard(card)
      handleCardPairDropped(card)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const isActive = canDrop && isOver
  let backgroundColor = 'white'
  if (isActive) {
    backgroundColor = 'darkgreen'
  } else if (canDrop) {
    backgroundColor = 'white'
  }

  useImperativeHandle(ref, () => ({
    resetDoubleBin() {
      setDroppedCard()
    }
  }));
  
  const removePairFromBin = (pair) => {
    handleCardPairRemoved(pair)
    setDroppedCard()
  }

  const selectCardForCompass = (card) => {
    handleSelectForCompass(card)
  }

  return (
    <>
      {droppedCard ? (
        <div className={'doublebin-box-container'} >
          <div className={'double-cardbox'} style={{ background: droppedCard.color }}>{droppedCard.title}</div>
          {!selectPhase ? (
            <div className={'doublebin-remove-container'}>
              <CloseCircleOutlined
                onClick={() => removePairFromBin(droppedCard)}
              />
            </div>
          ) : (
            <div className={'select-card-container'}>
              {selectedCards.length < 4 && !selectedCards.some(card => card === droppedCard) && <div onClick={() => selectCardForCompass(droppedCard)} className={'select-card-button'}>Selecionar</div>}
            </div>
          )}
        </div>
      ) : (
          <div ref={drop} style={{ ...style, backgroundColor }}>
            {isActive ? 'Solte Aqui' : 'Solte Aqui'}
          </div>)
      }
    </>
  )
})


