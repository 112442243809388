export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export function checkOverlap(area, filled_areas) {
  let check_area = []
  for (let i = 0; i < filled_areas.length; i++) {
      
      check_area = filled_areas[i];
      
      let bottom1 = area.y + area.squareSize;
      let bottom2 = check_area.y + check_area.squareSize;
      let top1 = area.y;
      let top2 = check_area.y;
      let left1 = area.x;
      let left2 = check_area.x;
      let right1 = area.x + area.squareSize;
      let right2 = check_area.x + check_area.squareSize;
      if (bottom1 < top2 || top1 > bottom2 || right1 < left2 || left1 > right2) {
        continue
      }
      return true;
  }
  return false;
}

export const shuffle = (array) => {
  var currentIndex = array.length, temporaryValue, randomIndex;

  while (0 !== currentIndex) {

    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}