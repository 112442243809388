import React, { useCallback, useState } from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from '../../utils/ItemTypes'
import { DraggableBox } from '../DraggableBox/DraggableBox'
import { snapToGrid as doSnapToGrid } from '../../utils/snapToGrid'
import update from 'immutability-helper'
import { getRandomArbitrary, shuffle } from '../../utils/index'
import { blueCards } from '../../utils/cards'

const CONTAINER_WIDTH = window.innerWidth * 0.7;
const CONTAINER_HEIGHT = window.innerHeight * 3;
let positionX = 0
let positionY = 0
let wildCard = -1
let count = 0

const styles = {
  width: CONTAINER_WIDTH,
  height: CONTAINER_HEIGHT,
  padding: 24,
  marginTop: 56,
  position: 'relative',
}
function renderBox(item, key, cardSelectionHandler) {
  return <DraggableBox key={key} id={key} cardSelectionHandler={cardSelectionHandler} {...item} />
}

const setCardInitialPos = (card) => {
  if (count >= blueCards.length) {
    console.log("countdown")
  }
    const littleRandom = getRandomArbitrary(0, 30);
    const x = positionX + littleRandom
    const y = positionY + littleRandom
  
    if (positionX > CONTAINER_WIDTH - 200) {
      positionX = 0
      positionY = positionY + 100
    } else {
      positionX = positionX + 90
    }
  
    wildCard = wildCard * (-1)
    count++
    
    return {
      top: y,
      left: x,
      title: card
    }
}

export const Container = ({ snapToGrid, cardSelectionHandler }) => {
  const [boxes, setBoxes] = useState(shuffle(blueCards).map(card => setCardInitialPos(card)))
  const moveBox = useCallback(
    (id, left, top) => {
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { left, top },
          },
        }),
      )
    },
    [boxes],
  )
  const [, drop] = useDrop({
    accept: ItemTypes.BOX,
    drop(item, monitor) {
      const delta = monitor.getDifferenceFromInitialOffset()
      let left = Math.round(item.left + delta.x)
      let top = Math.round(item.top + delta.y)
      if (snapToGrid) {
        ;[left, top] = doSnapToGrid(left, top)
      }
      moveBox(item.id, left, top)
      return undefined
    },
  })
  return (
      <div ref={drop} style={styles}>
        {Object.keys(boxes).map((key) => renderBox(boxes[key], key, cardSelectionHandler))}
      </div>
  )
}
