import React, { memo, useState } from 'react'

import "./style.css"
// import { Button } from 'antd';
import { DoubleBox } from '../DoubleBox';
import { SingleUseDropbin } from '../SingleUseDropbin';

export const CompassSelection = memo(function Container({ selectedPairs }) {
  const initialSelectedCards = selectedPairs.flat()
  const [selectedCards, setSelectedCards] = useState(initialSelectedCards);


  // const resetCompassPhase = () => {
  //   setSelectedCards(initialSelectedCards)
  // }

  const handleDrop = (card) => {
    setSelectedCards(selectedCards.filter(el => el !== card))
  }

  const handleUndoDrop = (card) => {
    setSelectedCards([...selectedCards, card])
  }

  return (
    <div className={'forth-phase-wrapper'}>
      {/* <Button
        className={'reset-phase-button'}
        onClick={() => resetCompassPhase()}
      >
        Reiniciar
        </Button> */}
      <h3>Rosa dos Ventos:</h3>
      <div className={'compass-bg-wrapper'}>
        <div className={'compass-top-wrapper'}>
          <SingleUseDropbin handleDrop={handleDrop} handleUndoDrop={handleUndoDrop} />
        </div>
        <div className={'compass-center-wrapper'} >
          <SingleUseDropbin handleDrop={handleDrop} handleUndoDrop={handleUndoDrop} />
          <SingleUseDropbin handleDrop={handleDrop} handleUndoDrop={handleUndoDrop} />
        </div>
        <div className={'compass-base-wrapper'} >
          <SingleUseDropbin handleDrop={handleDrop} handleUndoDrop={handleUndoDrop} />
        </div>
      </div>
      <h3>Cards Escolhidos:</h3>
      <div className={'all-cards-wrapper'}>
        {selectedCards.map(card => (
          <DoubleBox key={`id-${card.title} + ${selectedCards.indexOf(card)}`} card={card} />
        ))}
      </div>
    </div>
  );
})
