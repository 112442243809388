import React, { useState } from 'react'
import logospark from '../../assets/logospark.png'

import "./style.css"
const Login = ({ setCredentials }) => {
  const [message, setMessage] = useState('');

  return (
    <div className={'login-container'}>
      <img className={'logo-login'} src={logospark} alt='logo'/>
      <form onSubmit={e => {
        e.preventDefault();
        setCredentials(message);
      }}>
        <input
          type="password"
          onChange={e => setMessage(e.currentTarget.value)}
        />
        <input
          type="submit"
          value="Confirmar"
        />
      </form>
    </div>
  );
}

export default Login
