import { Button } from 'antd'
import React, { memo, useState, useRef } from 'react'
import { Doublebin } from '../Doublebin'
import { DoubleBox } from '../DoubleBox'
import { CloseCircleOutlined } from '@ant-design/icons';

import "./style.css"

export const Pyramid = memo(function Container({ selectedPairs, goToForthPhase }) {
  const initialSelectedPairs = selectedPairs.flat()
  const [pairs, setPairs] = useState(initialSelectedPairs);
  const [selectPhase, setSelectPhase] = useState(false)
  const [selectedCards, setSelectedCards] = useState([])
  const resetRefs = useRef([]);

  const handleDrop = (pair) => {
    return setPairs(pairs.filter(el => el !== pair))
  }

  const handleRemove = (pair) => {
    setPairs([...pairs, pair])
  }

  const resetDoublebins = () => {
    resetRefs.current.forEach(bin => {
      bin.resetDoubleBin();
    });
  }

  const resetThirdPhase = () => {
    setPairs(initialSelectedPairs)
    setSelectedCards([])
    resetDoublebins()
    setSelectPhase(false)
  }

  const handleSelectForCompass = (card) => {
    setSelectedCards([...selectedCards, card])
    if (selectedCards.length >= 4) {
      setSelectPhase(false)
    } else {
      setSelectPhase(true)
    }
  }

  const handleDeselectForCompass = (card) => {
    setSelectedCards(selectedCards.filter(el => el !== card))
  }

  return (
    <div className={'third-phase-wrapper'}>
      <Button className={'third-phase-reset'} onClick={() => resetThirdPhase()}>Reiniciar etapa</Button>
      {pairs.length === 0 && !selectPhase && <Button type='primary' className={'next-phase'} onClick={() => setSelectPhase(true)}>Confirmar Pirâmide</Button>}

      <div className={'pyramidbin-wrapper'}>
        <h3>Visão</h3>
        <div className={'pyramidbin-top-wrapper'}>
          <Doublebin ref={insert => resetRefs.current[0] = insert} handleCardPairDropped={handleDrop} handleCardPairRemoved={handleRemove} handleSelectForCompass={handleSelectForCompass} selectPhase={selectPhase} selectedCards={selectedCards} />
          <Doublebin ref={insert => resetRefs.current[1] = insert} handleCardPairDropped={handleDrop} handleCardPairRemoved={handleRemove} handleSelectForCompass={handleSelectForCompass} selectPhase={selectPhase} selectedCards={selectedCards} />
        </div>
        <h3>Estratégia</h3>
        <div className={'pyramidbin-middle-wrapper'}>
          <div className={"pyramid-paired-card"}>
            <Doublebin ref={insert => resetRefs.current[2] = insert} handleCardPairDropped={handleDrop} handleCardPairRemoved={handleRemove} handleSelectForCompass={handleSelectForCompass} selectPhase={selectPhase} selectedCards={selectedCards} />
            <Doublebin ref={insert => resetRefs.current[3] = insert} handleCardPairDropped={handleDrop} handleCardPairRemoved={handleRemove} handleSelectForCompass={handleSelectForCompass} selectPhase={selectPhase} selectedCards={selectedCards} />
          </div>
          <div className={"pyramid-paired-card"}>
            <Doublebin ref={insert => resetRefs.current[4] = insert} handleCardPairDropped={handleDrop} handleCardPairRemoved={handleRemove} handleSelectForCompass={handleSelectForCompass} selectPhase={selectPhase} selectedCards={selectedCards} />
            <Doublebin ref={insert => resetRefs.current[5] = insert} handleCardPairDropped={handleDrop} handleCardPairRemoved={handleRemove} handleSelectForCompass={handleSelectForCompass} selectPhase={selectPhase} selectedCards={selectedCards} />
          </div>
        </div>
        <h3>Tática</h3>
        <div className={'pyramidbin-base-wrapper'}>
          <div className={"pyramid-paired-card"}>
            <Doublebin ref={insert => resetRefs.current[6] = insert} handleCardPairDropped={handleDrop} handleCardPairRemoved={handleRemove} handleSelectForCompass={handleSelectForCompass} selectPhase={selectPhase} selectedCards={selectedCards} />
            <Doublebin ref={insert => resetRefs.current[7] = insert} handleCardPairDropped={handleDrop} handleCardPairRemoved={handleRemove} handleSelectForCompass={handleSelectForCompass} selectPhase={selectPhase} selectedCards={selectedCards} />
          </div>
          <div className={"pyramid-paired-card"}>
            <Doublebin ref={insert => resetRefs.current[8] = insert} handleCardPairDropped={handleDrop} handleCardPairRemoved={handleRemove} handleSelectForCompass={handleSelectForCompass} selectPhase={selectPhase} selectedCards={selectedCards} />
            <Doublebin ref={insert => resetRefs.current[9] = insert} handleCardPairDropped={handleDrop} handleCardPairRemoved={handleRemove} handleSelectForCompass={handleSelectForCompass} selectPhase={selectPhase} selectedCards={selectedCards} />
          </div>
          <div className={"pyramid-paired-card"}>
            <Doublebin ref={insert => resetRefs.current[10] = insert} handleCardPairDropped={handleDrop} handleCardPairRemoved={handleRemove} handleSelectForCompass={handleSelectForCompass} selectPhase={selectPhase} selectedCards={selectedCards} />
            <Doublebin ref={insert => resetRefs.current[11] = insert} handleCardPairDropped={handleDrop} handleCardPairRemoved={handleRemove} handleSelectForCompass={handleSelectForCompass} selectPhase={selectPhase} selectedCards={selectedCards} />
          </div>
          <div className={"pyramid-paired-card"}>
            <Doublebin ref={insert => resetRefs.current[12] = insert} handleCardPairDropped={handleDrop} handleCardPairRemoved={handleRemove} handleSelectForCompass={handleSelectForCompass} selectPhase={selectPhase} selectedCards={selectedCards} />
            <Doublebin ref={insert => resetRefs.current[13] = insert} handleCardPairDropped={handleDrop} handleCardPairRemoved={handleRemove} handleSelectForCompass={handleSelectForCompass} selectPhase={selectPhase} selectedCards={selectedCards} />
          </div>
        </div>
      </div>
      {selectedCards && selectedCards.length >= 4 && <Button type='primary' className={'next-phase'} onClick={() => goToForthPhase(selectedCards)}>Confirmar Escolha</Button>}
      {selectedCards && selectedCards.length > 0 && <div className={'all-cards-wrapper'}>
        {selectedCards.map(card => (
          <div key={`id-${card.title} + ${selectedCards.indexOf(card)}`}
            className={'cardbox'}
            style={{ background: card.color }}>
            {card.title}
            <CloseCircleOutlined
              style={{ fontSize: '16px' }}
              className={'card-selector-x'}
              onClick={() => handleDeselectForCompass(card)}
            />
          </div>
        ))}
      </div>}
      <div className={'cardPair-wrapper'}>
        {pairs.map(pair => <DoubleBox card={pair} />)}
      </div>
    </div>
  )
})
