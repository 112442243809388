import React from 'react'
import { useDrag } from 'react-dnd'
import { ItemTypes } from '../../utils/ItemTypes'

import "./style.css"

export const DoubleBox = ({ card }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { card, type: ItemTypes.BOX },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0.8 : 1
  return (
    <div ref={drag}  style={{ opacity }} className={'doubleBox-container'}>
      <div className={'cardbox'} style={{ background: card.color }}>{card.title}</div>
    </div>)
}
