import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from '../../utils/ItemTypes'
import { CloseCircleOutlined } from '@ant-design/icons';

import "./style.css"

const style = {
  height: '80px',
  width: '96px',
  marginRight: '1rem',
  marginBottom: '1rem',
  color: 'white',
  padding: '1rem',
  textAlign: 'center',
  fontSize: '10px',
  lineHeight: 'normal',
  float: 'left',
  border: '3px solid black',
  borderRadius: '8px',
}

export const SingleUseDropbin = forwardRef((props, ref) => {
  const { handleDrop, handleUndoDrop } = props;
  const [droppedCard, setDroppedCard] = useState()
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.BOX,
    drop: (item, monitor) => {
      const { title, color } = item.card
      setDroppedCard({ title, color })
      handleDrop(item.card)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const isActive = canDrop && isOver
  let backgroundColor = 'gray'
  if (isActive) {
    backgroundColor = 'darkgreen'
  } else if (canDrop) {
    backgroundColor = 'darkkhaki'
  }

  const cardRemoverHandler = (card) => {
    setDroppedCard()
    handleUndoDrop(card)
  }

  useImperativeHandle(ref, () => ({
    resetDropBin() {
      setDroppedCard()
    }
  }));

  return (
    <>
      {droppedCard ? (
        <div className={'dropped-card-single-use'} style={{ backgroundColor: droppedCard.color }}>
          {droppedCard.title}
          <CloseCircleOutlined
            className={'drop-x-single'}
            onClick={() => { 
            cardRemoverHandler(droppedCard)
            }}
          />
        </div>
      ) : (
        <div ref={drop} style={{ ...style, backgroundColor }}>
          {isActive ? 'Solte Aqui' : 'Arraste um card aqui'}
        </div>)
      }
    </>
  )
})