import React, { memo, useState, useRef } from 'react'
import { Dropbin } from '../Dropbin'
import { SimpleBox } from '../SimpleBox'
import { redCards } from '../../utils/cards'
import { shuffle } from '../../utils'
import { Button } from 'antd'

import "./style.css"

export const SecondPhaseContainer = memo(function Container({ selectedCards, groupCardPair, reset }) {
  const initialRedCards = shuffle(redCards).slice(0, 7)
  const initialBlueCards = shuffle(selectedCards).slice(0, 7)
  const rowKeys = [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7]
  const resetRefs = useRef([]);

  const [selectedBlueCards, setSelectedBlueCards] = useState(initialBlueCards);
  const [selectedRedCards, setSelectedRedCards] = useState(initialRedCards);

  const cardSelectionHandler = (card, color) => {
    if(color === '#353F7E') {
      return setSelectedBlueCards(selectedBlueCards.filter(el => el !== card))
    }
    if(color === '#C7332D') {
      return setSelectedRedCards(selectedRedCards.filter(el => el !== card))
    } else {
      return
    }
  }

  const handleReturnCardToPile = (card, color) => {
    if(color === '#353F7E') {
      return setSelectedBlueCards([...selectedBlueCards, card])
    }
    if(color === '#C7332D') {
      return setSelectedRedCards([...selectedRedCards, card])
    } else {
      return
    }
  }

  const resetDropBin = () => {
    resetRefs.current.forEach(dropbin => {
      dropbin.resetDropBin(); 
  });
  }

  const resetSecondPhase = () => {
    setSelectedRedCards(initialRedCards)
    setSelectedBlueCards(initialBlueCards)
    reset()
    resetDropBin()
  }
  
  return (
    <div className={'second-phase-wrapper'}>
      <Button onClick={() => resetSecondPhase()}>Reiniciar etapa</Button>
      <div className={'dropbin-wrapper'}>
        {rowKeys.map((keys, index) => {
          return <Dropbin 
            key={`keyId_${index}`}
            ref={ins => resetRefs.current[index] = ins}
            handleCardRemoval={handleReturnCardToPile}
            groupCardPair={groupCardPair}
            rowKey={keys.rowKeys}
          />})}
      </div>
      <div className={'cards-wrapper'}>
        {selectedRedCards.map( card => <SimpleBox key={`key_${card}`} selectionHandler={cardSelectionHandler} title={card} cardColor='#C7332D' />)}
        {selectedBlueCards.map( card => <SimpleBox key={`key_${card}`} title={card} selectionHandler={cardSelectionHandler} cardColor='#353F7E' />)}
      </div>
    </div>
  )
})
