export const blueCards = [
"FOCO",
"HUMOR",
"HABILIDADE",
"LEVEZA",
"LIMITE",
"IMPULSO",
"INQUIETAÇÃO",
"IDEIA",
"INSPIRAÇÃO",
"IMAGINAÇÃO",
"INSIGHT",
"INTERFERÊNCIA",
"INÉRCIA",
"MINDSET",
"MAIS",
"MENOS",
"NOVO",
"NÃO",
"OBJETIVO",
"ORGANIZAÇÃO",
"OPERAÇÃO",
"OPORTUNIDADE",
"PESSOA",
"PRIORIDADE",
"AMBIENTE",
"ATITUDE",
"AÇÃO",
"ALINHAMENTO",
"BARREIRA",
"BLOQUEIO",
"CRIATIVIDADE",
"CLIENTE",
"CLAREZA",
"CRENÇA",
"COMUNICAÇÃO",
"COMPETÊNCIA",
"CENÁRIO ",
"DIFERENÇA",
"DESAFIO",
"DIVERSIDADE",
"DESIGN",
"DIREÇÃO",
"ENERGIA",
"ENTREGA",
"ESCOLHA",
"ESTRATÉGIA",
"ERRO",
"FERRAMENTAS",
"PLANEJAMENTO",
"PROPÓSITO",
"PROCESSO",
"PRODUTO",
"PROBLEMA",
"RELACIONAMENTO",
"REALIDADE",
"RUÍDO",
"SIMPLICIDADE",
"SERVIÇO",
"SUPORTE",
"SIM",
"TRANSPARÊNCIA",
"TÁTICA",
"VALORES",
"VISÃO",
"VERSÃO",
"PROTÓTIPO",
"ÁGIL",
"CONFLITO",
"CONSCIÊNCIA",
"CONFIANÇA",
"POSSIBILIDADE",
"IMPORTANTE",
"URGENTE"
]

export const redCards = [
"AVANÇAR",
"ADAPTAR",
"AGIR",
"CONECTAR",
"COMUNICAR",
"CONVERGIR",
"CRIAR",
"DESCONECTAR",
"DESCOBRIR",
"DIALOGAR",
"DIVERSIFICAR",
"DEFINIR",
"DESENVOLVER",
"ELABORAR",
"ENCONTRAR",
"ELIMINAR",
"ESCUTAR",
"ESCOLHER",
"EXPERIMENTAR",
"EXPLORAR",
"ENTENDER",
"FACILITAR",
"FOCAR",
"FAZER",
"FLEXIBILIZAR",
"IDENTIFICAR",
"IMAGINAR",
"IMPLEMENTAR",
"INOVAR",
"MUDAR",
"MELHORAR",
"MAXIMIZAR",
"MINIMIZAR",
"ORGANIZAR",
"ORIENTAR",
"PREPARAR",
"PERGUNTAR",
"PRIORIZAR",
"PROJETAR",
"PLANEJAR",
"RECOMEÇAR",
"REVER",
"REPENSAR",
"REINVENTAR",
"REDUZIR",
"REFLETIR",
"SUBSTITUIR",
"SUPERAR",
"SATISFAZER",
"SUSTENTAR",
"SILENCIAR",
"SIMPLIFICAR",
"QUESTIONAR",
"TRANSFORMAR",
"VALORIZAR",
"VIABILIZAR",
"COLABORAR"
]