import React from 'react'
import { useDrag } from 'react-dnd'
import { ItemTypes } from '../../utils/ItemTypes'

import "./style.css"

export const SimpleBox = ({ title, cardColor, selectionHandler }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { title, color: cardColor, type: ItemTypes.BOX },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        selectionHandler(item.title, cardColor)
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0.4 : 1
  return (
    <div ref={drag} className={'simple-box-container'} style={{ opacity, background: cardColor }}>
      {title}
    </div>
  )
}
