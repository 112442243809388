import React, { useState } from 'react'
import { message, Button, Tabs } from 'antd';

import { Container } from '../Container/Container'
import { CustomDragLayer } from '../CustomDragLayer/CustomDragLayer'
import { Box } from '../Box'
import { Pyramid } from '../Pyramid';
import { CompassSelection } from '../CompassSelection';
import { SecondPhaseContainer } from '../SecondPhaseContainer';
import logospark from '../../assets/logo2.png'
import { groupBy } from 'lodash';

import 'antd/dist/antd.css';
import "./style.css"

// const mockedStuff = [{title: "SUBSTITUIR", color: "#C7332D", rowKey: undefined},
// {title: "OBJETIVO", color: "#353F7E", rowKey: undefined},
// {title: "FAZER", color: "#C7332D", rowKey: undefined},
// {title: "NOVO", color: "#353F7E", rowKey: undefined},
// {title: "RUÍDO", color: "#353F7E", rowKey: undefined},
// {title: "TRANSFORMAR", color: "#C7332D", rowKey: undefined},
// {title: "REVER", color: "#C7332D", rowKey: undefined},
// {title: "SILENCIAR", color: "#C7332D", rowKey: undefined},
// {title: "VISÃO", color: "#353F7E", rowKey: undefined},
// {title: "ENTENDER", color: "#C7332D", rowKey: undefined},
// {title: "NÃO", color: "#353F7E", rowKey: undefined},
// {title: "MAIS", color: "#353F7E", rowKey: undefined},
// {title: "CRIAR", color: "#C7332D", rowKey: undefined},
// {title: "IMPULSO", color: "#353F7E", rowKey: undefined}]

export const Game = () => {
  const forcedArray = [0,1,2,3,4,5,6]
  const [selectedCards, setSelectedCards] = useState([])
  const [selectedCardPairs, setselectedCardPairs] = useState([])
  const [formatedCardPairs, setFormatedCardPairs] = useState([])
  const [forcedArr, setForcedArr] = useState(forcedArray)
  const [forcedTabKey, setForcedTabKey] = useState('1')
  const { TabPane } = Tabs;

  const handleCardSelection = (cardTitle) => {
    let cardsToBeSet = []
    let shellArray = forcedArray
    if (selectedCards.includes(cardTitle)) {
      cardsToBeSet = selectedCards.filter(el => el !== cardTitle)
    } else if (selectedCards.length >= 7) {
      return message.error('Você só pode escolher 7 cartas');
    } else {
      cardsToBeSet = [...selectedCards, cardTitle]
    }
    shellArray.splice(0, cardsToBeSet.length)
    setForcedArr(shellArray)
    setSelectedCards(cardsToBeSet)
  }

  const groupCardPair = (card, rowKey, toBeRemoved) => {
      if(toBeRemoved) {
        let cardsToBeSet = []
        cardsToBeSet = selectedCardPairs.filter(el => el.title !== card)
        setselectedCardPairs(cardsToBeSet)
      } else {
        const { title, color } = card;
        setselectedCardPairs([...selectedCardPairs, {title, color, rowKey}])
      }
  }

  const handlePhaseChange = (cards) => {
    if (selectedCards.length < 7) {
      return message.error('Por favor escolha 7 cartas');
    }
    if (forcedTabKey === '1') {
      setForcedTabKey('2');
    }
    if (forcedTabKey === '2') {
      setForcedTabKey('3');
      formatCardPairs(selectedCardPairs);
    }
    if (forcedTabKey === '3') {
      if (formatedCardPairs.length < 4) {
        return message.error('Por favor escolha 4 cartas');
      }
      setForcedTabKey('4');
      setFormatedCardPairs(cards)
    }
  }

  const formatCardPairs = (cardPairs) => {
    const groupedPairs = groupBy(cardPairs, 'rowKey')
    setFormatedCardPairs(groupedPairs)
  }

  const resetPhase = () => {
    setselectedCardPairs([])
  }

  return (
    <div>
      <img className={'logo'} src={logospark} alt='logo'/>
      <Tabs className={'tabs'} centered defaultActiveKey="3" activeKey={forcedTabKey}>
        <TabPane tab="Preparação" disabled={forcedTabKey !== '1'} key="1">
          <div className={'first-phase-wrapper'}>
            <div className={'first-phase-cards'}>
              <span>Cards Selecionados:</span>
              {selectedCards.map(card => <Box cardSelectionHandler={handleCardSelection} hasSelector={false} title={card} key={`id_${card}`}/>)}
              {selectedCards.length === 7 && <Button type='primary' size={'large'} onClick={() => handlePhaseChange()}>Confirmar escolha</Button>}
              {forcedArr.map(shell => <div className={'first-phase-shell'} key={shell}/>) }
            </div>
            <Container cardSelectionHandler={handleCardSelection} snapToGrid={false} />
            <CustomDragLayer snapToGrid={false} />
          </div>
        </TabPane>
        <TabPane disabled={forcedTabKey !== '2'} tab="Em fila" key="2">
          {selectedCardPairs.length === 14 && <Button className={'second-phase-button'} type='primary' size={'large'} onClick={() => handlePhaseChange()}>Confirmar escolha</Button>}
          <SecondPhaseContainer reset={resetPhase} groupCardPair={groupCardPair} selectedCards={selectedCards} snapToGrid={false}/>
        </TabPane>
        <TabPane disabled={forcedTabKey !== '3'} tab="Pirâmide" key="3">
          {<Pyramid goToForthPhase={handlePhaseChange} selectedPairs={Object.values(formatedCardPairs)} />}
        </TabPane>
        <TabPane disabled={forcedTabKey !== '4'} tab="Rosa dos Ventos" key="4">
          {<CompassSelection selectedPairs={Object.values(formatedCardPairs)} />}
        </TabPane>
      </Tabs>
    </div>
  )
}

export default Game;
