
import React, { useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Game from './Components/Game'
import Login from './Components/Login'
const pw = process.env.REACT_APP_USR_PW;

function App() {
  const [credentials, setCredentials] = useState('')
  return (
    <div className="App">
      <DndProvider backend={HTML5Backend}>
      {credentials !== pw && <Login setCredentials={setCredentials} />}
        {credentials === pw && <Game />}
      </DndProvider>
    </div>
  );
}

export default App;
